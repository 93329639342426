export class Subfamilia {
    constructor(
        public ID_SUBF: number,
        public ID_FLIA: number,
        public NSUBF: string
    ) {}
}

export class Familia {
    constructor(
        public ID_FLIA: number,
        public NFAMILIA: string,
        public SUBFAMILIAS: Subfamilia[]
    ) {}
}
