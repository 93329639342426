import React from 'react';  
import { Redirect } from 'react-router-dom';
import { isValidUser } from './../../store/auth/auth.actions';
import { useSelector } from 'react-redux';

export default (WrappedComponent: any) => (props: any) => {
    const autoLoginValid = isValidUser(); // This HOC runs before tryAutoLogin, we need this when hitting private URLs directly
    const authenticated = useSelector((state: any) => state.authStore.token) !== ''; // In case session gets expired, we need this to logout

    if (autoLoginValid || authenticated) {
        return  <WrappedComponent {...props} /> 
    }

    return <Redirect to='/' />;
};