import React from 'react';
import classNames from 'classnames';
import './Input.scss';
import NumberFormat from 'react-number-format';

export interface InputMap {
    change?: any,
    className?: any
    maxLength?: number,
    minLength?: number,
    type: string,
    placeholder?: string,
    required?: boolean,
    id: any,
    value?: number|string,
    keyPress?: any
};

const Input: React.FunctionComponent<InputMap> = React.memo((props: InputMap) => {
    const { type, id, placeholder, required, minLength, maxLength, change, className, value, keyPress } = props;

    const handleFocus = (event: any) => event.target.select();

    if (type === 'number') {
        return (
            <NumberFormat
                className={classNames('Mag-Input', className)}
                allowNegative={false}
                decimalScale={1}
                format='####'
                placeholder={placeholder}
                required={required}
                minLength={minLength}
                maxLength={maxLength}
                onFocus={handleFocus}
                onChange={change} 
                onKeyPress={keyPress}
                value={value} />
        );
    }

    return <input 
        className={classNames('Mag-Input', className)}
        type={type}
        id={id}
        placeholder={placeholder}
        required={required}
        minLength={minLength}
        maxLength={maxLength}
        onChange={change}
        onKeyPress={keyPress}
        value={value}
    />
});

export default Input;