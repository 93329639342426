import axios from 'axios';
import constants from './../../constants';
import { Dispatch } from 'react';
import { IContactForm } from '../../pages/Contact/Contact';

export const FETCH_CONTACT = 'FETCH_CONTACT';
export const FETCH_CONTACT_SUCCESS = 'FETCH_CONTACT_ERROR';
export const FETCH_CONTACT_ERROR = 'FETCH_FAMILIAS_ERROR';
export const RESET_CONTACT_STATE = 'RESET_CONTACT_STATE';

export const resetContactState = () => ({ type: RESET_CONTACT_STATE });

const fetchContact = () => ({ type: FETCH_CONTACT });
const fetchContactError = () => ({ type: FETCH_CONTACT_ERROR });
const fetchContactSuccess = () => ({ type: FETCH_CONTACT_SUCCESS });

export const sendContact = (contactBody: IContactForm): Dispatch<any> => (dispatch: Dispatch<any>) => {
    dispatch(fetchContact());

    return axios.post(`${constants.server}/contacto`, contactBody)
        .then(() => dispatch(fetchContactSuccess()))
        .catch(() => dispatch(fetchContactError()));
};
