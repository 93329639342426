import axios from 'axios';
import constants from './../../constants';
import { Familia, Subfamilia } from './../../classes/familia';
import { Dispatch } from 'react';

export const FETCH_FAMILIAS = 'FETCH_FAMILIAS';
export const FETCH_FAMILIAS_SUCCESS = 'FETCH_FAMILIAS_SUCCESS';
export const FETCH_FAMILIAS_ERROR = 'FETCH_FAMILIAS_ERROR';

const fetchFamilias = () => ({ type: FETCH_FAMILIAS });
const fetchFamiliasError = () => ({ type: FETCH_FAMILIAS_ERROR });
const fetchFamiliasSuccess = (familias: Familia[]) => ({ familias, type: FETCH_FAMILIAS_SUCCESS });

export const requestFamilias = () => (dispatch: Dispatch<any>) => dispatch(getFamilias());

const getFamilias = (): Dispatch<any> => (dispatch: Dispatch<any>) => {
    dispatch(fetchFamilias());

    return axios.get(`${constants.server}/familias`)
        .then((response: any) => {
            const familias: Familia[] = response.data.map((familia: Familia) => {
                familia.SUBFAMILIAS = familia.SUBFAMILIAS.map(s => new Subfamilia(s.ID_SUBF, s.ID_FLIA, s.NSUBF));
                return new Familia(familia.ID_FLIA, familia.NFAMILIA, familia.SUBFAMILIAS);
            });
            dispatch(fetchFamiliasSuccess(familias));
        })
        .catch(() => dispatch(fetchFamiliasError()));
};
