import { updateState } from './../helpers';
import { 
    FETCH_FAMILIAS,
    FETCH_FAMILIAS_ERROR,
    FETCH_FAMILIAS_SUCCESS
} from './familias.actions';

const initialState = {
    error: false,
    familias: [],
    fetching: false
};

export default (state = initialState, action: any = {}) => {
    switch (action.type) {
        case FETCH_FAMILIAS: return updateState(state, { error: false, fetching: true });
        case FETCH_FAMILIAS_ERROR: return updateState(state, { error: true, fetching: false });
        case FETCH_FAMILIAS_SUCCESS: return updateState(state, { 
            error: false, 
            familias: action.familias, 
            fetching: false 
        });
        default: return state;
    }
};
