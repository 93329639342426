import { 
    FETCH_CONTACT,
    FETCH_CONTACT_ERROR,
    FETCH_CONTACT_SUCCESS,
    RESET_CONTACT_STATE
} from './contact.actions';

const initialState = {
    error: false,
    fetching: false,
    msgSent: false
};

export default (state = initialState, action: any = {}) => {
    switch (action.type) {
        case FETCH_CONTACT: return { error: false, fetching: true, msgSent: false };
        case FETCH_CONTACT_ERROR: return { error: true, fetching: false, msgSent: false };
        case FETCH_CONTACT_SUCCESS: return { error: false, fetching: false, msgSent: true };
        case RESET_CONTACT_STATE: return initialState;
        default: return state;
    };
};
