import React from 'react';
import './Slider.scss';

const Slider: React.FunctionComponent<{}> = React.memo(() => (
    <div>
        <div 
            id="magSliderControls" 
            className="Slider carousel slide" 
            data-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img 
                        className="d-block w-100" 
                        src="./assets/slider/1.jpg" 
                        alt="Ilumros iluminacion rosario"
                    />
                    <div className="container">
                        <div className="carousel-caption d-md-block SliderCaption">
                            <h1>Nuevo concepto en iluminación</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="SliderBar">
            <div className="SliderBlueBar"></div>
            <div className="SliderYellowBar"></div>
        </div>
    </div>
));

export default Slider;