import React from 'react';
import './Error.scss';
import Button from '../../core/Button/Button';

const Error: React.FC<{ retry?: () => void, msg?: string }> = React.memo(({ msg = 'Hubo un error en el servidor. Por favor, intente nuevamente.', retry }) => (
    <div className="text-center">
        <span className="ErrorIcon"></span>
        <p>{msg}</p>
        {retry ? <Button click={retry}>Intentar nuevamente</Button> : null}
    </div>
));

export default Error;