import React, { useEffect, useCallback } from 'react';
import moment from 'moment';
import { Helmet } from "react-helmet";
import { getPedidos } from './../../store/pedidos/pedidos.actions';
import { useSelector, useDispatch } from 'react-redux';
import { Pedclim } from '../../classes/pedido';
import Error from './../../components/Error/Error';
import Spinner from '../../core/Spinner/Spinner';
import Currency from '../../core/Currency/Currency';
import './Orders.scss';

const Orders: React.FC<{}> = React.memo(() => {
    const { pedidos, error, fetching } = useSelector((state: any) => state.pedidosStore);
    const dispatch = useDispatch();

    const fetchPedidos = useCallback(() => {
        dispatch(getPedidos());
    }, [dispatch]);
    
    useEffect(() => fetchPedidos(), [fetchPedidos]);
    
    const renderPedidos = () => {
        if (error) {
            return <Error />;
        }
    
        if (fetching) {
            return <Spinner>Cargando pedidos...</Spinner>;
        }

        if (!pedidos.length) {
            return <p>No se han encontrado pedidos en su historial.</p>
        }

        return pedidos.map((p: Pedclim, index: number) => (
            <div key={index} className="PedidoItem">
                <p className="DateLabel">Fecha del pedido # <b>{p.NPEDIDO}</b></p>
                <p className="DateValue">{moment(p.FMOV).format("DD/MM/YYYY")}</p>

                <p className="SuCompra">Su compra:</p>
                <ul>
                    {p.PEDCLIDS.map((pd: any, i: number) => <li key={i}>{pd.CANT_PED} {pd.CODART && pd.CODART.DESART}</li>)}
                </ul>

                <hr />
                <p className="TotalPedido">Importe: <Currency value={p.TOTAL} /></p>
            </div>
        ));
    };

    return (
        <div className="container SectionContainer">
            <Helmet>
                <title>ILUMROS | Mis Pedidos</title>
                <meta name="description" content="ILUMROS  Soluciones en proyectos de iluminación. Empresa con más de dos décadas de existencia en el mercado. Nos dedicamos a la fabricación, importación, distribución y venta de una amplia gama de artefactos de iluminación." />
                <meta name="keywords" content="ilumrus, iluminacion, rosario, proyectos, concepto de iluminacion, luz, lamparas, fabricacion, artefactos" />
            </Helmet>
            <h3 className="SectionTitle">Mis Pedidos</h3>
            {renderPedidos()}
        </div>
    );
});

export default Orders;