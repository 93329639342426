import React from 'react';
import './Spinner.scss';

const Spinner: React.FunctionComponent<{ children?: string }> = React.memo(({ children = 'Cargando...' }) => (
    <div className="SpinnerContainer text-center">
        <div className="spinner-border"></div>
        <h3>{children}</h3>
    </div>
));

export default Spinner;