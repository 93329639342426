export class Pedclim {
    constructor(
        public NPEDIDO: number,
        public CVIAJ: string,
        public FMOV: string,
        public CODCLI: string,
        public TOTAL: number,
        public OBSER: string,
        public BAJADO: boolean,
        public PEDCLIDS: Pedclid[]
    ) {}
}

export class Pedclid {
    constructor(
        public NPEDIDO: number,
        public CODART: string,
        public CANT_PED: number,
        public PVENTA: number,
        public DESART: string,
        public PRECIO_UNITARIO: number
    ) {}
}
