import React, { useState } from 'react';
import Input from '../../core/Input/Input';
import { useHistory } from 'react-router-dom';
import './Search.scss';

const Search: React.FunctionComponent<any> = () => {
    const [ filter, setFilter ] = useState('');
    const history = useHistory();

    const setFilterText = (evt: any = {}) => {
        setFilter(evt.target.value);
    };

    const applyFilterSearch = () => {
        history.push(`/productos?filter=${filter}`);
    };
    
    const onKeyPress = (event: any = {}) => {
        if (event.key === 'Enter' && filter) {
            applyFilterSearch();
        }
    };
    
    return (
        <div className="SearchContainer">
            <Input 
                id="ProductSearch" 
                type="text"
                keyPress={onKeyPress}
                className="SearchInput"
                change={setFilterText}
                placeholder="Buscar artículo por nombre..."
                value={filter}
            />
            <span 
                onClick={applyFilterSearch}
                className="searchIcon">
            </span>
        </div>
    );
};

export default Search;