import { updateState } from './../helpers';
import { Pedclim } from '../../classes/pedido';
import { 
    FETCH_PEDIDOS,
    FETCH_PEDIDOS_ERROR,
    FETCH_PEDIDOS_SUCCESS,
    RESET_PEDIDO_FETCH,
    SUBMIT_PEDIDO,
    SUBMIT_PEDIDO_ERROR,
    SUBMIT_PEDIDO_SUCCESS
} from './pedidos.actions';

interface IPedidosState {
    error: boolean,
    fetching: boolean,
    pedidos: Pedclim[],
    requestDone: boolean
};

const initialState: IPedidosState = {
    error: false,
    fetching: false,
    pedidos: [],
    requestDone: false
};

export default (state = initialState, action: any = {}) => {
    switch (action.type) {
        case FETCH_PEDIDOS: return updateState(state, { error: false, fetching: true, requestDone: false });
        case FETCH_PEDIDOS_ERROR: return updateState(state, { error: true, fetching: false, requestDone: false });
        case FETCH_PEDIDOS_SUCCESS: 
            return updateState(state, { 
                error: false,
                pedidos: action.pedclims, 
                fetching: false,
                requestDone: true
            });
        case RESET_PEDIDO_FETCH: return updateState(state, { error: false, fetching: false, requestDone: false });
        case SUBMIT_PEDIDO: return updateState(state, {
            error: false,
            fetching: true,
            requestDone: false
        });
        case SUBMIT_PEDIDO_ERROR: return updateState(state, {
            error: true,
            fetching: false,
            requestDone: false
        });
        case SUBMIT_PEDIDO_SUCCESS: return updateState(state, {
            error: false,
            fetching: false,
            requestDone: true
        });
        default: return state;
    }
};