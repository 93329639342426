import React from 'react';
import './WpBubble.scss';

const WpBubble: React.FC<{}> = React.memo(() => {
    
    const openWp = () => {
        window.location.href = 'https://wa.me/5493416627374';
    };

    return (
        <div 
            onClick={openWp}
            className="WpBubble">
            <span className="WpIcon"></span>
        </div>
    )
});

export default WpBubble;