
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import AppRouter from './AppRouter';
import configureStore from './store/configureStore';
import { Provider } from 'react-redux';
import './index.scss';
import { Store, CombinedState } from 'redux';
import ReduxToastr from 'react-redux-toastr'
const store: Store<CombinedState<any>> = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <div>
            <AppRouter />
            <ReduxToastr
                timeOut={4000}
                newestOnTop={false}
                preventDuplicates
                position="top-right"
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                progressBar
                closeOnToastrClick
            />
        </div>
    </Provider>, 
document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
