import React, { useCallback, useEffect } from 'react';
import { Helmet } from "react-helmet";
import Slider from './../../components/Slider/Slider';
import './Home.scss';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from '../../core/Spinner/Spinner';
import Articulo from '../../classes/articulo';
import { Familia } from '../../classes/familia';
import ProductItem, { IProductItem } from '../../components/ProductItem/ProductItem';
import Cliente from '../../classes/cliente';
import Button from '../../core/Button/Button';
import { useHistory } from 'react-router-dom';
import { requestFeaturedArticulos } from '../../store/articulos/articulos.actions';
const Home: React.FunctionComponent<{}> = React.memo(() => {
    const { articulos, error, fetching } = useSelector((state: any) => state.articulosStore);
    const familias: Familia[] = useSelector((state: any) => state.familiasStore.familias);
    const cliente: Cliente = useSelector((state: any) => state.authStore.user);
    const listapre: number = cliente && cliente.LISTAPRE;
    // const showCart: boolean = cliente !== null;
    const history = useHistory();

    const dispatch = useDispatch();
    const fetchData = useCallback(() => {
        dispatch(requestFeaturedArticulos());
    }, [dispatch]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);
    
    const getProductPrice = (articulo: Articulo): number => {
        switch (listapre) {
            case 1: return articulo.PVENTA_1;
            case 2: return articulo.PVENTA_2;
            case 3: return articulo.PVENTA_3;
            case 4: return articulo.PVENTA_4;
            default: return articulo.PVENTA_2;
        };
    };

    const renderFeaturedProducts = () => {
        if (fetching) {
            return <Spinner>Buscando productos destacados...</Spinner>;
        }

        if (error || !articulos || articulos.length === 0) return;

        return (
            <>
                <h1>Productos destacados</h1>
                <div className="separator"></div>
                <div className="row">
                    {articulos.map((a: Articulo, index: number) => {
                        const familia: Familia|undefined = familias.find((f: Familia) => f.ID_FLIA === a.ID_FLIA);
                        const product: IProductItem = {
                            codart: a.CODART,
                            desart: a.DESART,
                            familia: familia && familia.NFAMILIA,
                            subfamilia: '',
                            price: getProductPrice(a),
                            imgUrl: a.FILE1,
                            showPrice: false
                            // showCart
                        };

                        return <ProductItem key={index} {...product} />
                    })}
                </div>
            </>
        );
    }

    const goToProducts = () => {
        history.push('productos');
    }

    return (
        <div>
            <Helmet>
                <title>ILUMROS | Nuevo concepto en iluminación</title>
                <meta name="description" content="ILUMROS  Soluciones en proyectos de iluminación. Empresa con más de dos décadas de existencia en el mercado. Nos dedicamos a la fabricación, importación, distribución y venta de una amplia gama de artefactos de iluminación." />
                <meta name="keywords" content="ilumrus, iluminacion, rosario, proyectos, concepto de iluminacion, luz, lamparas, fabricacion, artefactos" />
            </Helmet>
            <Slider />
            <div className="Home container text-center">
                <div className="row">
                    <div className="col-md-12 PresentationContainer">
                        <h1>Soluciones en proyectos de iluminación</h1>
                        <div className="separator"></div>
                        <p className="description">Somos una empresa con <b>más de dos décadas de existencia</b> en el mercado. Nos dedicamos a la <i>fabricación, importación, distribución y venta</i> de una amplia gama de artefactos de iluminación.</p>
                        <Button className="comprarBtn" click={goToProducts}>COMENZAR A COMPRAR</Button>
                    </div>
                </div>
                <div className="ProductosDestacados">
                    {renderFeaturedProducts()}
                </div>
            </div>
        </div>
    );
});

export default Home;