import React from 'react';
import classNames from 'classnames';
import './Button.scss';

const Button: React.FC<{ 
    click?: () => void, 
    children: string|number, 
    className?: any,
    type?: 'button'|'submit'|'reset'
}> = ({ click, children, className, type = 'button' }) => (
    <button 
        type={type}
        className={classNames('btn MainButton', className)}
        onClick={click}>{children}
    </button>
);

export default Button;