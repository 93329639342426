import React from 'react';
import NavList, { NavListItemMap } from '../NavList/NavList';
import { useSelector } from 'react-redux';
import './AuthNav.scss';

const AuthNav: React.FunctionComponent<{}> = React.memo(() => {
    const authenticated: boolean = useSelector((state: any) => state.authStore.user) !== null;
    const navItems: NavListItemMap[] = [];

    if (authenticated) {
        navItems.push(
            { activeClassName: 'active-route', className: '', exact: true, label: 'Mis pedidos', to: '/mis-pedidos' },
            { activeClassName: 'active-route', className: '', exact: true, label: 'Carrito', to: '/checkout', icon: 'cart' },
            { activeClassName: 'active-route', className: '', exact: true, label: 'Salir', to: '/logout' }
        );
    } else {
        navItems.push(
            { activeClassName: 'active-route', className: '', exact: true, label: 'Ingresar', to: '/ingresar' },
            { activeClassName: 'active-route', className: '', exact: true, label: 'Registrarme', to: '/registrarme' }
        );
    }

    return (
        <div className="AuthNavContainer">
            <div className="container">
                <NavList items={navItems} className="AuthNavLinks" />
            </div>
        </div>
    );
});

export default AuthNav;