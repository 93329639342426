import React from 'react';
import './Footer.scss';

const Footer: React.FunctionComponent<{}> = React.memo(() => {
    return (
        <footer className="FooterContainer">
            <div className="MainFooterContainer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <p>
                                <span className="SmallIcon EmailIcon"></span> 
                                <a href="mailto:ventas@ilumros.com.ar"> <b>ventas</b>@ilumros.com.ar</a>
                            </p>
                            <p>
                                <span className="SmallIcon PhoneIcon"></span> Oficina: (0341) 465-0101
                            </p>
                            <p>
                                <span className="SmallIcon WpIcon"></span> Oficina: 3416627374
                            </p>
                            <p>
                                <span className="SmallIcon GpsIcon"></span> <b>Pje. Thorne 5573, Rosario, Santa Fe</b>
                            </p>
                            <p>
                                <span className="SmallIcon TimeIcon"></span> Lunes a Viernes – 6 a 14:45hs
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="SubfooterContainer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">ILUMROS  | 2020</div>
                        <div className="col-md-6 col-sm-6 PowerBy">
                            <a 
                                href="http://huskysoftware.com.ar" 
                                target="_blank"
                                rel="noopener noreferrer">Desarrollo <span className="husky-logo"></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
});

export default Footer;
