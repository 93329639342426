import React, { useEffect, useCallback, useRef } from 'react';
import { Route } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from 'react-redux'
import * as familiasActions from '../../store/familias/familias.actions';
import FamilyNav from './../../components/FamiliaNav/FamiliaNav';
import Error from './../../components/Error/Error';
import ProductList from './../../components/ProductList/ProductList';
import Spinner from './../../core/Spinner/Spinner';
import Search from '../../components/Search/Search';
import qs from 'qs';
import './Products.scss';
import { Familia } from '../../classes/familia';

const Products: React.FunctionComponent<any> = ({ match, location }) => {
    const { filter, familia, subfamilia } = qs.parse(location.search, { ignoreQueryPrefix: true });
    const { error, familias, fetching } = useSelector((state: any) => state.familiasStore);
    const dispatch = useDispatch();
    window.scrollTo(0, 0);
    const fetchData = useCallback(() => {
        if (!familias.length) dispatch(familiasActions.requestFamilias());
    }, [dispatch, familias.length]);

    const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop);
    const myRef = useRef(null);
    const executeScroll = () => scrollToRef(myRef)

    useEffect(() => {
        fetchData();
        const f: Familia = familias.find((f: Familia) => f.ID_FLIA === Number(familia));

        const hasNoSubfamilia = f && f.SUBFAMILIAS.length === 0;

        if (filter || hasNoSubfamilia || subfamilia) {
            executeScroll();
        }
        // eslint-disable-next-line
    }, [fetchData, filter, familia, subfamilia]);

    if (error) {
        return (
            <div className="ProductsError"><Error retry={fetchData}  /></div>
        );
    }

    if (fetching) {
        return (
            <div className="container ProductsContainer">
                <Spinner>Buscando productos y categorías...</Spinner>
            </div>
        );
    }

    return (
        <div className="container">
            <Helmet>
                <title>ILUMROS | Productos</title>
                <meta name="description" content="ILUMROS  Soluciones en proyectos de iluminación. Empresa con más de dos décadas de existencia en el mercado. Nos dedicamos a la fabricación, importación, distribución y venta de una amplia gama de artefactos de iluminación." />
                <meta name="keywords" content="ilumrus, iluminacion, rosario, proyectos, concepto de iluminacion, luz, lamparas, fabricacion, artefactos" />
            </Helmet>
            
            <div className="container ProductsContainer">
                <div className="ProductFilterContainer">
                    <Search />
                </div>
                <div className="row">
                    <div className="FamiliasContainer col-md-3">
                        <div className="FamiliaList">
                            <FamilyNav familias={familias} match={match} />
                        </div>
                    </div>
                    <div className="ProductListContainer col-md-9" ref={myRef}>
                        <Route path={`${match.path}/`} component={ProductList}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Products;