import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import { combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import articulosStore from './articulos/articulos.reducer';
import authStore from './auth/auth.reducer';
import cartStore from './cart/cart.reducer';
import contactStore from './contact/contact.reducer';
import familiasStore from './familias/familias.reducer';
import pedidosStore from './pedidos/pedidos.reducer';
import { reducer as toastrReducer } from 'react-redux-toastr';

const rootReducer = combineReducers({
    articulosStore,
    authStore,
    cartStore,
    contactStore,
    familiasStore,
    pedidosStore,
    toastr: toastrReducer // <- Mounted at toastr.
});

const middlewares: any[]= [thunkMiddleware];

if (process.env.NODE_ENV === 'development') {
    middlewares.push(createLogger())
}

export default () => {
    return createStore(
        rootReducer,
        applyMiddleware(...middlewares)
    );
};