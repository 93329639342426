import React, { useState } from 'react';
import Button from './../../core/Button/Button';
import Input from './../../core/Input/Input';

export interface FormMap {
    name: string, 
    elements: FormElementMap[], 
    submitText: string,
    onSubmit: any
};

export interface FormElementMap {
    elementType: string, 
    id: any, 
    placeholder?: string, 
    valueType: string, 
    required?: boolean, 
    minLength?: number, 
    maxLength?: number
};

const Form: React.FunctionComponent<FormMap> = (props: FormMap) => {
    const { name, elements, submitText } = props;
    const defaultElements: any = {};
    props.elements.forEach(element => defaultElements[element.id] = '');

    const [ formElements, setFormElementsState ] = useState(defaultElements);

    const handleChange = (event: any = {}): void => {
        setFormElementsState({
            ...formElements,
            [event.target.id]: event.target.value
        });
    };
    
    const handleSubmit = (event: any): void => {
        event.preventDefault();
        props.onSubmit(formElements);
    };

    const renderElement = (el: any) => {
        const { elementType, id, placeholder, valueType, required, minLength, maxLength } = el;

        if (elementType === 'input') {
            return (
                <div
                    key={id} 
                    className="form-group">
                    <Input 
                        id={id}
                        type={valueType}
                        placeholder={placeholder}
                        required={required}
                        minLength={minLength}
                        maxLength={maxLength}
                        value={formElements[id]}
                        change={handleChange}
                        className="form-control"
                    />
                </div>
            );
        }
    }

    return (
        <form
            name={name}
            onSubmit={handleSubmit}>
            {elements.map((el: FormElementMap) => renderElement(el))}
            <Button type="submit">{submitText}</Button>
        </form>
    );
}

export default Form;